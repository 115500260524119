import Vue from "vue";
import Component from "vue-class-component";

@Component
export class HasShiftSupport extends Vue {
  shiftKeyDown = false;

  mounted() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      if (event.key === "Shift") {
        self.shiftKeyDown = true;
      }
    });

    window.addEventListener("keyup", function (event) {
      if (event.key === "Shift") {
        self.shiftKeyDown = false;
      }
    });
  }
}
