
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import { Action, Getter } from "vuex-class";
  import { IndexAccountPayload } from "@/store/modules/account.store";
  import { MyWorkActivityRowColumn } from "@/components/my-work/activity-row.vue";
  import { MyWorkActivityGroupData, MyWorkActivityGroupType } from "@/components/my-work/activity-group.vue";
  import { HasActionLabels } from "@/mixins/activities/has-action-labels";
  import { HasTaskActions } from "@/mixins/has-task-actions";
  import { HasShiftSupport } from "@/mixins/has-shift-support";
  import { HasMyWorkActions } from "@/mixins/activities/has-my-work-actions";
  import { TaskStatus } from "@/store/modules/task.store";
  import { MyWorkFilter } from "./parts/filter.vue";
  import { includes } from "lodash";

  @Component({
    components: {
      PartFilter: () => import("./parts/filter.vue"),
      MyWorkActivityGroup: () => import("@/components/my-work/activity-group.vue"),
      TaskSelectToast: () => import("@/components/task/select-toast.vue"),
    },
  })
  export default class MyWork extends Mixins(HasTaskActions, HasActionLabels, HasMyWorkActions, HasShiftSupport) {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("account/all") accounts!: Account[];

    @Action("my-work/indexActivities") indexActivities!: (payload: MyWorkActivityIndexPayload) => MyWorkActivityList;
    @Action("my-work/searchActivities") searchActivities!: (payload: MyWorkActivityIndexPayload) => MyWorkActivityList;
    @Action("my-work/indexUnscheduledActivities") indexUnscheduledActivities!: (payload: MyWorkActivityIndexPayload) => MyWorkActivity[];
    @Action("my-work/indexDueTasks") indexDueTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTask[];
    @Action("my-work/indexTodayTasks") indexTodayTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTask[];
    @Action("my-work/indexNextSevenDaysTasks") indexNextSevenDaysTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTask[];
    @Action("my-work/indexNextFourteenDaysTasks") indexNextFourteenDaysTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTask[];
    @Action("my-work/indexUnscheduledTasks") indexUnscheduledTasks!: (payload: MyWorkTaskIndexPayload) => MyWorkTask[];
    @Action("account/indexForCurrentOffice") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;

    columns: MyWorkActivityRowColumn[] = [
      { type: "name", width: "300px", showInHeader: false },
      { type: "work_status", width: "126px", label: "Fase" },
      { type: "office_name", width: "300px", label: "Kantoornaam" },
      { type: "column_expander", width: "100%" },
      { type: "legal_documents_uploaded", width: "20px", label: "Basisdocumenten" },
      { type: "task_count", width: "120px", label: "Aantal taken" },
      { type: "date", width: "120px", label: "Datum" },
      { type: "user", width: "32px", label: "Gebruiker" },
    ];

    groups: MyWorkActivityGroupData[] = [];

    myWorkActivityList: MyWorkActivityList = {
      due: [],
      today: [],
      next_7_days: [],
      next_14_days: [],
      total: 0,
    };

    myWorkTaskList: MyWorkTaskList = {
      due: [],
      today: [],
      next_7_days: [],
      unscheduled: [],
      next_14_days: [],
      total: 0,
    };

    filter: MyWorkFilter = {
      status: [],
      taskGroup: [],
      users: [],
    };

    search: string = "";

    openedMyWorkActivityRows: { id: number; due: MyWorkActivityGroupType }[] = [];

    loadingTasksForActivities: { id: number; due: MyWorkActivityGroupType }[] = [];

    activityLabels: MyWorkActivityActionLabel[] = [];

    /**
     * Return a unique list of all activity ids that can be found in the my work groups.
     *
     * @returns number[]
     */
    get allActivityIds() {
      return [...new Set(this.groups.flatMap((r) => r.activities?.map((a) => a.id) || []))];
    }

    /**
     * Return the user that is selected in the filter
     *
     * @returns Account | null
     */
    get selectedUser() {
      return this.filter && this.filter.users ? this.accounts.find((a) => Number(a.id) === this.filter!.users[0]) : null;
    }

    async mounted() {
      this.filter.users = [this.me.id];

      await this.indexAccounts({ per_page: 1000 });
    }

    /**
     * Load activities for the current user
     * Note: there is a distinction between scheduled and unscheduled activities for performance reasons
     *
     * @returns void
     */
    async loadActivities() {
      if (!this.filter) {
        return;
      }

      const indexPayload: MyWorkActivityIndexPayload = {
        q: this.createMyWorkActivityQuery(this.filter!.status),
        taskQ: this.createMyWorkTaskQuery(this.filter!.users[0], null, this.filter!.taskGroup, this.search),
        per_page: 200,
      };

      const newActivities = await this.indexActivities(indexPayload);

      if (newActivities) {
        this.myWorkActivityList = { ...newActivities };
      }

      this.myWorkActivityList.unscheduled = await this.indexUnscheduledActivities(indexPayload);

      this.updateGroups();

      await this.loadLabels(this.allActivityIds, this.filter!.users[0]);
    }

    async loadLabels(activityIds: number[], userId: number, merge: boolean = false, type: string | null = null) {
      const labels = await this.getActivityLabels(activityIds, userId, merge, type);

      if (labels) {
        if (merge) {
          this.activityLabels = this.activityLabels.concat(labels);
        } else {
          this.activityLabels = labels;
        }
      }
    }

    /**
     * Toggle opened activity rows. If the activity is already opened, close it. Otherwise, open it.
     * Also index tasks for the activity if it's opened.
     *
     * @Param activityId: number - the id of the activity
     * @Param type: MyWorkActivityGroupType - the type of the activity
     * @returns void
     */
    toggleOpenedActivity(activityId: number, type: MyWorkActivityGroupType) {
      this.clearCheckedTasks();

      if (this.openedMyWorkActivityRows.find((r) => r.id === activityId && r.due === type)) {
        this.openedMyWorkActivityRows = [];
        return;
      }

      this.openedMyWorkActivityRows = [{ id: activityId, due: type }];

      this.indexTasksForActivity(activityId, type);
    }

    /**
     * Index specific types of tasks for an activity
     *
     * @Param activityId: number - the id of the activity
     * @Param type: MyWorkActivityGroupType - the type of the activity
     * @returns void
     */
    async indexTasksForActivity(activityId: number, type: MyWorkActivityGroupType) {
      if (this.loadingTasksForActivities.find((t) => t.id === activityId && t.due === type)) {
        return;
      }

      this.loadingTasksForActivities.push({ id: activityId, due: type });

      const indexPayload = {
        q: this.createMyWorkTaskQuery(this.filter!.users[0], activityId, this.filter!.taskGroup, this.search),
        per_page: 200,
      };

      const callbacks: { [key in MyWorkActivityGroupType]: string } = {
        due: "indexDueTasks",
        today: "indexTodayTasks",
        unscheduled: "indexUnscheduledTasks",
        next_7_days: "indexNextSevenDaysTasks",
        next_14_days: "indexNextFourteenDaysTasks",
      };

      // @ts-ignore - this is a dynamic call to the current component
      this.myWorkTaskList[type] = await this[callbacks[type]](indexPayload);

      this.loadingTasksForActivities = this.loadingTasksForActivities.filter((t) => t.id !== activityId && t.due !== type);
    }

    /**
     * Index tasks for all opened activity rows
     *
     * @returns void
     */
    async indexTasksForOpenRows() {
      for (const row of this.openedMyWorkActivityRows) {
        await this.indexTasksForActivity(row.id, row.due);
      }
    }

    /**
     * Update groups with the current list of activities
     *
     * @param {boolean} [openNonEmptyGroups=false] - if true, open groups that have activities (used to open groups when searching)
     * @returns void
     */
    updateGroups(openNonEmptyGroups = false) {
      if (!this.myWorkActivityList) {
        return;
      }

      this.groups = [
        {
          title: "Deadline verstreken",
          open: openNonEmptyGroups && this.myWorkActivityList!.due ? this.myWorkActivityList!.due.length > 0 : true,
          type: "due",
          color: "red",
          activities: this.myWorkActivityList!.due,
          clickHandler: this.toggleOpenedActivity,
        },
        {
          title: "Vandaag",
          open: openNonEmptyGroups && this.myWorkActivityList!.today ? this.myWorkActivityList!.today.length > 0 : true,
          type: "today",
          color: "green",
          activities: this.myWorkActivityList!.today,
          clickHandler: this.toggleOpenedActivity,
        },
        {
          title: "Geen actie gepland",
          open: openNonEmptyGroups && this.myWorkActivityList!.unscheduled ? this.myWorkActivityList!.unscheduled!.length > 0 : false,
          type: "unscheduled",
          color: "yellow",
          activities: this.myWorkActivityList!.unscheduled ? this.myWorkActivityList!.unscheduled : [],
          clickHandler: this.toggleOpenedActivity,
        },
        {
          title: "Komende 7 dagen",
          open: openNonEmptyGroups && this.myWorkActivityList!.next_7_days ? this.myWorkActivityList!.next_7_days.length > 0 : false,
          type: "next_7_days",
          color: "gray-300",
          activities: this.myWorkActivityList!.next_7_days,
          clickHandler: this.toggleOpenedActivity,
        },
        {
          title: "Komende 14 dagen",
          open: openNonEmptyGroups && this.myWorkActivityList!.next_14_days ? this.myWorkActivityList!.next_14_days.length > 0 : false,
          type: "next_14_days",
          color: "gray-300",
          activities: this.myWorkActivityList!.next_14_days,
          clickHandler: this.toggleOpenedActivity,
        },
      ];
    }

    /**
     * @name Handle group opened event
     * @description When a group is opened, update the group meta data in the list.
     *
     * @param {MyWorkActivityGroupData} group - the group that was opened
     * @returns void
     */
    handleGroupOpened(group: MyWorkActivityGroupData) {
      this.groups = this.groups.map((g) => (g.type === group.type ? { ...g, open: true } : g));

      if (!["next_7_days", "next_14_days"].includes(group.type)) {
        return;
      }

      const activityIds = group.activities?.map((a) => a.id) ?? [];

      this.loadLabels(activityIds, this.filter!.users[0], true, group.type);
    }

    /**
     * @name Handle group closed event
     * @description When a group is closed, update the group meta data in the list.
     *
     * @param {MyWorkActivityGroupData} group - the group that was closed
     * @returns void
     */
    handleGroupClosed(group: MyWorkActivityGroupData) {
      this.groups = this.groups.map((g) => (g.type === group.type ? { ...g, open: false } : g));
    }

    /**
     * @name Handle task date changed event
     * @description Update the deadline of a task and move it to the correct group in the list.
     *
     * @param {{ deadline: string; id: number }} payload - the new deadline and the id of the task
     * @param {MyWorkActivityGroupType} type - the type of the activity
     * @returns void
     */
    async handleMyWorkDateChanged(payload: { deadline: string; id: number }, type: MyWorkActivityGroupType) {
      const tasks = this.checkedOrClickedTasks(payload.id, type);

      const updatedTasks = await this.handleDateChanged(payload);

      // update the tasks in tasks with the deadline from updatedTasks
      if (updatedTasks) {
        tasks.forEach((task) => {
          const updatedTask = updatedTasks.find((t) => t.id === task.id);
          if (updatedTask && updatedTask.deadline) {
            task.deadline = updatedTask.deadline;
          }
        });
      }

      if (tasks.length) {
        // remove all tasks from this.myWorkTaskList[type] that are in tasks
        this.myWorkTaskList[type] = this.myWorkTaskList[type].filter((t) => !tasks.find((task) => task.id === t.id));

        const date = new Date(payload.deadline);
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);
        const nextTwoWeeks = new Date();
        nextTwoWeeks.setDate(today.getDate() + 14);

        if (date < today) {
          this.myWorkTaskList.due = this.myWorkTaskList.due.concat(tasks);
          if (type !== "due") {
            this.clearCheckedTasks();
          }
        } else if (date.toDateString() === today.toDateString()) {
          this.myWorkTaskList.today = this.myWorkTaskList.today.concat(tasks);
          if (type !== "today") {
            this.clearCheckedTasks();
          }
        } else if (date < nextWeek) {
          this.myWorkTaskList.next_7_days = this.myWorkTaskList.next_7_days.concat(tasks);
          if (type !== "next_7_days") {
            this.clearCheckedTasks();
          }
        } else if (date < nextTwoWeeks) {
          this.myWorkTaskList.next_14_days = this.myWorkTaskList.next_14_days.concat(tasks);
          if (type !== "next_14_days") {
            this.clearCheckedTasks();
          }
        }

        await this.loadActivities();

        this.updateGroups();
      }
    }

    /**
     * @name Handle task status changed event
     * @description Update the status of a task and remove it from the list if it's completed.
     *
     * @param {{ status: TaskStatus; id: number }} payload - the new status and the id of the task
     * @param {MyWorkActivityGroupType} type - the my work tasks type
     */
    async handleMyWorkStatusChanged(payload: { status: TaskStatus; id: number }, type: MyWorkActivityGroupType) {
      this.handleStatusChanged(payload);

      const tasks = this.checkedOrClickedTasks(payload.id, type);
      if (!tasks.length) {
        return;
      }

      const otherTasks = this.myWorkTaskList[type].filter((t) => t.activity_id === tasks[0].activity_id && t.id !== payload.id && ![TaskStatus.done, TaskStatus.na].includes(t.status));

      if (otherTasks.length === 0) {
        this.myWorkActivityList[type] = this.myWorkActivityList[type]!.filter((a) => a.id !== tasks[0].activity_id);
        this.updateGroups();
      }

      tasks.forEach((task) => {
        task.status = payload.status;
      });

      this.myWorkTaskList[type] = this.myWorkTaskList[type].map((t) => {
        const updatedTask = tasks.find((task) => task.id === t.id);
        return updatedTask ? updatedTask : t;
      });

      // TODO: this is the only method to reactivly update the task in the list.
      // THe logic should be re-written where tasks are collected in their own component and we can apply propper reactivity.
      if (![TaskStatus.done, TaskStatus.na].includes(payload.status)) {
        return;
      }
      //@ts-ignore
      if (this.$refs["task_" + payload.id] && Array.isArray(this.$refs["task_" + payload.id]) && this.$refs["task_" + payload.id]!.length > 0) {
        //@ts-ignore
        const $task = this.$refs["task_" + payload.id][0];

        $task.$el.parentNode.removeChild($task.$el);
      }
    }

    /**
     * @name Checked or clicked task
     * @description return the checked tasks or the clicked task. This is useful after changing the status because only one task can be clicked
     *
     * @param {number} taskId - the id of the task that was clicked
     * @param {MyWorkActivityGroupType} type - the type of the activity group
     * @returns MyWorkTask[]
     */
    checkedOrClickedTasks(taskId: number, type: MyWorkActivityGroupType) {
      if (this.checkedTasks.length) {
        return this.myWorkTaskList[type].filter((t) => this.checkedTasks.includes(Number(t.id)));
      }

      return this.myWorkTaskList[type].filter((t) => Number(t.id) === Number(taskId));
    }

    /**
     * Handle search input
     *
     * @param {string} query - the search query
     * @returns void
     */
    handleSearch(query: string) {
      this.search = query;
    }

    openedTasksForActivity(activityId: number, type: MyWorkActivityGroupType) {
      // when searching the activitylist also contains the tasks, so check this first
      const activity = this.myWorkActivityList && this.myWorkActivityList[type] != null ? this.myWorkActivityList[type]!.find((a) => a.id === activityId) : null;
      if (activity && activity.tasks) {
        return activity.tasks;
      }

      if (!this.myWorkTaskList[type]) {
        return [];
      }

      return this.myWorkTaskList[type].filter((t) => t.activity_id === activityId).sort((a, b) => (a.deadline < b.deadline ? -1 : 1));
    }

    /**
     * Handle task check changed event
     *
     * @param {number} taskId - the id of the task that was checked
     * @returns void
     */
    handleTaskCheckChangedCustom(payload: { taskId: number; activityId: number; type: MyWorkActivityGroupType; event: MouseEvent }) {
      const { taskId, activityId, type, event } = payload;
      if (this.shiftKeyDown && this.checkedTasks.length > 0) {
        let tasks = this.openedTasksForActivity(activityId, type);

        if (!tasks.length) {
          this.handleTaskCheckChanged(taskId);
          return;
        }

        let clickedIndex = tasks.findIndex((t) => t.id === taskId);

        // get all the checked tasks from this activity
        let checkedTasks = tasks.filter((t) => this.checkedTasks.includes(t.id));
        let checkedIndexes = checkedTasks.map((t) => tasks.findIndex((task) => task.id === t.id));

        // get the first and last checked index
        let firstCheckedIndex = Math.min(...checkedIndexes);
        let lastCheckedIndex = Math.max(...checkedIndexes);

        // get all ids from the tasks between the first or last checked index depending on which one is closer to the clicked index
        let ids = [];
        if (clickedIndex < firstCheckedIndex) {
          for (let i = clickedIndex; i <= firstCheckedIndex; i++) {
            ids.push(tasks[i].id);
          }
        } else {
          for (let i = lastCheckedIndex; i <= clickedIndex; i++) {
            ids.push(tasks[i].id);
          }
        }

        if (ids.length === 0) {
          this.handleTaskCheckChanged(taskId);
          return;
        }

        // get the checked state of the clicked task
        const clickedState = this.checkedTasks.includes(taskId);

        // set the checked state of all ids to the opposite of the clicked task
        ids.forEach((id) => {
          if (clickedState) {
            this.checkedTasks = this.checkedTasks.filter((t) => t !== id);
          } else if (!this.checkedTasks.includes(id)) {
            this.checkedTasks.push(id);
          }
        });
      } else {
        this.handleTaskCheckChanged(taskId);
      }
    }

    /**
     * reset the view
     *
     * @returns void
     */
    reset() {
      this.myWorkActivityList = {
        due: [],
        today: [],
        next_7_days: [],
        next_14_days: [],
        total: 0,
      };

      this.myWorkTaskList = {
        due: [],
        today: [],
        next_7_days: [],
        unscheduled: [],
        next_14_days: [],
        total: 0,
      };

      this.clearCheckedTasks();
      this.openedMyWorkActivityRows = [];
      this.loadingTasksForActivities = [];
    }

    /**
     * Open all activity rows
     *
     * @returns void
     */
    openAllActivityRows() {
      let openedRows: { id: number; due: MyWorkActivityGroupType }[] = [];

      this.myWorkActivityList.due.forEach((activity) => {
        openedRows.push({ id: activity.id, due: "due" });
      });

      this.myWorkActivityList.today.forEach((activity) => {
        openedRows.push({ id: activity.id, due: "today" });
      });

      this.myWorkActivityList.next_7_days.forEach((activity) => {
        openedRows.push({ id: activity.id, due: "next_7_days" });
      });

      this.myWorkActivityList.next_14_days.forEach((activity) => {
        openedRows.push({ id: activity.id, due: "next_14_days" });
      });

      if (this.myWorkActivityList.unscheduled) {
        this.myWorkActivityList.unscheduled.forEach((activity) => {
          openedRows.push({ id: activity.id, due: "unscheduled" });
        });
      }

      this.openedMyWorkActivityRows = openedRows;
    }

    /**
     * Get the label for an activity (used to show the date on the activity row)
     *
     * @Param activityId: number - the id of the activity
     * @Param type: MyWorkActivityGroupType - the type of the activity
     *
     * @returns MyWorkActivityActionLabel | undefined
     */
    getActivityLabel(activityId: number, type: MyWorkActivityGroupType) {
      return this.activityLabels.find((l) => l.activity_id === activityId && l.label === type.toUpperCase());
    }

    @Watch("filter", { deep: true })
    filterChanged() {
      this.reset();

      this.loadActivities();
    }

    @Watch("search")
    async searchChanged(query: string) {
      this.reset();

      if (query && query.length) {
        const indexPayload = {
          q: this.createMyWorkActivityQuery(this.filter!.status),
          taskQ: this.createMyWorkTaskQuery(this.filter!.users[0], null, this.filter!.taskGroup, query),
        };

        this.myWorkActivityList = await this.searchActivities(indexPayload);

        await this.loadLabels(this.allActivityIds, this.filter!.users[0]);

        this.updateGroups(query.length ? true : false);

        this.openAllActivityRows();
      } else {
        await this.loadActivities();
      }
    }
  }
