import { Action, Getter, Mutation } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { MyWorkActivityActionLabelTypes } from "@/enums/my-work";

@Component
export class HasActionLabels extends Vue {
  @Getter("my-work/activityActionLabels") activityActionLabels!: MyWorkActivityActionLabel[];

  @Action("my-work/indexMyWorkActivityActionLabels") indexMyWorkActivityActionLabels!: (payload: MyWorkActivityIndexPayload) => MyWorkActivityActionLabel[];

  loadingActivityLabels: boolean = false;

  /**
   * @name fetch my work activity labels
   * @description Get a list of all activity labels based on an array of activityIds and a User id.
   * An optional merge parameter can be passed to merge the activity labels into the current set, if not the whole set of labels will be replaced.
   *
   * @param {number[]} activityIds - Array of activity ids
   * @param {number} userId - User id
   * @param {boolean} [merge=false] - Merge the activity labels into the current set
   * @param {string} [type=null] - The activity label type
   * @returns {Promise<MyWorkActivityActionLabel[]>} activityLabels
   */
  async getActivityLabels(activityIds: number[], userId: number, merge: boolean = false, type: string | null = null) {
    try {
      this.loadingActivityLabels = true;
      const indexQuery: MyWorkActivityIndexPayload = {
        taskQ: {
          "assigned_to.id": userId,
          action_labels: type ? [type.toLocaleUpperCase()] : null,
          or: {
            activity_id: activityIds,
          },
        },
      };

      return await this.indexMyWorkActivityActionLabels(indexQuery);
    } catch (e) {
      console.log("Error fetching activity labels");
      console.error(e);
    }

    this.loadingActivityLabels = false;
  }
}
